import React, { Component } from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Iframe from '../components/iframe'

import fullLogo from '../assets/images/vitapoint-full-logo.svg'

class IOSetup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Vitapoint IO Setup',
      src: process.env.GATSBY_IFRAME_URL,
      width: '1080px',
      height: '1280px',
    }
  }

  render() {
    console.log(this.state.src)
    return (
      <Layout>
        <SEO title='Setup' />
        <div
          style={{
            maxWidth: `720px`,
            margin: `0 auto`,
            marginBottom: `1.45rem`,
          }}
        >
          <img
            src={fullLogo}
            alt='Vitapoint'
            style={{
              width: `100%`,
              marginTop: `1.5rem`,
              marginBottom: `1.5rem`,
            }}
          />
        </div>
        <Iframe
          title={this.state.title}
          width={this.state.width}
          height={this.state.height}
          source={this.state.src}
        />
      </Layout>
    )
  }
}

export default IOSetup
